import { ApplicationConfig } from './application.config.type';

export const config: ApplicationConfig = {
  env: 'production',
  annotationService: {
    url: 'https://annotate.oseberg.io',
  },
  exportService: {
    url: 'https://ai-exports.oseberg.io',
  },
  auth: {
    url: 'https://auth.oseberg.io',
    appName: 'goat',
  },
  predictions: {
    retryIntervalMs: 1000,
    retryAttempts: 120,
  },
  annotations: {
    autoCollapseThreshold: 25,
  },
  undoRedo: {
    maxEntries: 10,
  },
  queues: {
    bulkMax: 50000,
  },
  flags: {
    features: {
      queues: true,
      manage: false,
      search: true,
      admin: true,
    },
    layout: {
      dragAndDrop: false,
    },
  },
  agGridKey:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-061955}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Oseberg,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{OseBerg_Inc}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{OseBerg_Inc}_need_to_be_licensed___{OseBerg_Inc}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_August_2025}____[v3]_[01]_MTc1NTgxNzIwMDAwMA==273622fb09e82a68cb1040a5768ead8e',
};
